import {
    SET_CRM_LIST,
    SET_CURRENT_PLAN,
    SET_CURRENT_MAPPING,
} from '../constants';

const initialState = {
    CRM_list: [],
    subscription: {},
    mapping: null,
};

export const userInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_MAPPING:
            return {
                ...state,
                mapping: action.payload,
            }
        case SET_CRM_LIST:
            return {
                ...state,
                CRM_list: action.payload,
            }
        case SET_CURRENT_PLAN:
            return {
                ...state,
                subscription: action.payload,
            }
        default:
            return state;
    }
};
