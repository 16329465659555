import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import * as builder from "../../ducks/builder";
import PP from './PrivacyPolicy'
import ToS from './TermsOfService'

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pp: false,
      tos: false
    }
  }

  editpp = (status) => {
    this.setState({pp: status})
  }
  edittos = (status) => {
    this.setState({tos: status})
  }

  render() {
    const today = new Date().getFullYear();
    return (
      <div
        className={`kt-footer ${this.props.footerClasses} kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop`}
        id="kt_footer"
      >
        <PP 
          modal={this.state.pp}
          setModal={this.editpp}
        />

        <ToS 
          modal={this.state.tos}
          setModal={this.edittos}
        />

        <div className={`kt-container ${this.props.footerContainerClasses}`}>
          <div className="kt-footer__copyright">
            {today.toString()}&nbsp;&copy;&nbsp;
            <a
              href="http://trainermetrics.com"
              target="_blank"
              rel="noopener noreferrer"
              className="kt-link"
            >
              TrainerMetrics
            </a>
          </div>
          <div className="kt-footer__menu">
            <span
              style={{cursor: 'pointer'}}
              onClick={() => this.setState({tos: true})}
              rel="noopener noreferrer"
              className="kt-footer__menu-link kt-link"
            >
              Terms of Service
            </span>
            <span
              style={{cursor: 'pointer'}}
              onClick={() => this.setState({pp: true})}
              rel="noopener noreferrer"
              className="kt-footer__menu-link kt-link"
            >
              Privacy Policy
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  fluid:
    objectPath.get(store.builder.layoutConfig, "footer.self.width") === "fluid",
  footerClasses: builder.selectors.getClasses(store, {
    path: "footer",
    toString: true
  }),
  footerContainerClasses: builder.selectors.getClasses(store, {
    path: "footer_container",
    toString: true
  })
});

export default connect(mapStateToProps)(Footer);
