import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialState = {
  items: [],
};

export const crmSlice = createSlice({
  name: "crm",
  initialState,
  reducers: {
    setCrmList: (state, { payload }) => {
      state.items = payload;
    },
  },
});

export const { setCrmList } = crmSlice.actions;

export const selectCrm = createSelector(
  (state) => state.items,
  (items) => items
);

export default crmSlice.reducer;
