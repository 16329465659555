import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import Header from "./header/Header";
// import SubHeader from "./sub-header/SubHeader";
import HeaderMobile from "./header/HeaderMobile";
import AsideLeft from "./aside/AsideLeft";
import Footer from "./footer/Footer";
// import ScrollTop from "../../app/partials/layout/ScrollTop";
import HTMLClassService from "./HTMLClassService";
import LayoutConfig from "./LayoutConfig";
import * as MenuConfig from "./MenuConfig";
import LayoutInitializer from "./LayoutInitializer";
import QuickPanel from "../../app/partials/layout/QuickPanel";
import KtContent from "./KtContent";
import Alert from "./Alert.js";
import "./assets/Base.scss";

const htmlClassService = new HTMLClassService();

function Layout(props) {
  const {
    children,
    asideDisplay,
    // subheaderDisplay,
    selfLayout,
    layoutConfig,
    // contentContainerClasses,
    location,
    alert,
  } = props;
  htmlClassService.setConfig(layoutConfig);
  // scroll to top after location changes
  // window.scrollTo(0, 0);

  useEffect(() => {
    const elem = document.querySelector(".grecaptcha-badge");
    if (elem) elem.style.display = "none";

    if (props.auth.user && props.auth.user.email && props.auth.user.fullName) {
      window.delighted.survey({
        email: props.auth.user.email,
        name: props.auth.user.fullName,
        createdAt: "today",
        initialDelay: 2592000,
        properties: {
          tier: props.auth.user.tier,
        },
      });
    }

    if (layoutConfig.custom_color) {
      let style = document.createElement("style");
      style.type = "text/css";
      style.innerHTML =
        // '.kt-aside .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-text, ' +
        // '.kt-aside .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__link-text,' +
        // '.kt-aside .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__link-icon,' +
        // '.kt-aside .kt-menu__nav > .kt-menu__item.kt-menu__item--active  > .kt-menu__link .kt-menu__link-icon, ' +
        // '.kt-aside .kt-menu__nav > .kt-menu__item:not(.kt-menu__item--active):hover > .kt-menu__link .kt-menu__link-text, ' +
        // '.kt-aside .kt-menu__nav > .kt-menu__item:not(.kt-menu__item--active):hover  > .kt-menu__link .kt-menu__link-icon, ' +
        // '.kt-aside .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):hover > .kt-menu__link .kt-menu__link-text,' +
        ".kt-content .kt-portlet .dataoutput-data," +
        // '.kt-content .kt-portlet > .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-title,' +
        ".kt-content .kt-portlet .kt-portlet__body .custom-col .info-line__result," +
        ".theme-data" +
        "{ color: " +
        layoutConfig.custom_color +
        "!important;} " +
        // '.kt-aside .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item:hover > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot > span,' +
        ".kt-notification .kt-notification__item:hover " +
        "{ background-color: #F7F8FA!important;}" +
        ".kt-notification .kt-notification__item:hover .kt-notification__item-details .kt-notification__item-title" +
        "{ color: #595d6e!important; }" +
        ".drop-menu__item:hover, .drop-menu__item.active " +
        "{ background-color: #F7F8FA!important;}";
      document.getElementsByTagName("head")[0].appendChild(style);
    }
  }, [layoutConfig, props]);

  const [visibleMin, setVisibleMin] = useState(false);
  const contentCssClasses = htmlClassService.classes.content.join(" ");

  return selfLayout !== "blank" ? (
    <LayoutInitializer
      menuConfig={
        MenuConfig[
          MenuConfig.getName(props.location?.pathname, props.auth.user.roles)
        ]
      }
      layoutConfig={LayoutConfig}
      htmlClassService={htmlClassService}
    >
      {/* <!-- begin:: Header Mobile --> */}
      <HeaderMobile />
      {/* <!-- end:: Header Mobile --> */}

      <div className={`kt-grid kt-grid--hor kt-grid--root`}>
        {/* <!-- begin::Body --> */}
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
          {/* <!-- begin:: Aside Left --> */}
          {asideDisplay && (
            <>
              <AsideLeft
                location={location.pathname}
                visibleMin={visibleMin}
                setVisibleMin={setVisibleMin}
              />
            </>
          )}
          {/* <!-- end:: Aside Left --> */}
          <div
            className={`kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper ${
              visibleMin ? " min-bar " : ""
            }`}
            id="kt_wrapper"
          >
            {/* <!-- begin:: Header READY --> */}

            <Header />
            {/* <!-- end:: Header --> */}

            {/* <!-- begin:: Content --> */}
            <div
              id="kt_content"
              className={`kt-content ${contentCssClasses} kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}
            >
              {/* <!-- begin:: Content Head --> */}
              {/* {subheaderDisplay && (
                <SubHeader />
              )} */}
              {/* <!-- end:: Content Head --> */}

              {/* <!-- begin:: Content Body --> */}
              {/* TODO: add class to animate  kt-grid--animateContent-finished */}
              <KtContent>{children}</KtContent>
              {/*<!-- end:: Content Body -->*/}
            </div>
            {/* <!-- end:: Content --> */}
            <Footer />
            <Alert data={alert} />
          </div>
        </div>
        {/* <!-- end:: Body --> */}
      </div>
      <QuickPanel />
      {/* <ScrollTop /> */}
    </LayoutInitializer>
  ) : (
    // BLANK LAYOUT
    <div className={`kt-grid kt-grid--ver kt-grid--root`}>
      <KtContent>{children}</KtContent>
    </div>
  );
}

const mapStateToProps = ({ builder: { layoutConfig }, auth }) => ({
  layoutConfig,
  auth: auth,
  alert: auth.alert,
  selfLayout: objectPath.get(layoutConfig, "self.layout"),
  asideDisplay: objectPath.get(layoutConfig, "aside.self.display"),
  subheaderDisplay: objectPath.get(layoutConfig, "subheader.display"),
  desktopHeaderDisplay: objectPath.get(
    layoutConfig,
    "header.self.fixed.desktop"
  ),
  contentContainerClasses: "",
  // contentContainerClasses: builder.selectors.getClasses(store, {
  //   path: "content_container",
  //   toString: true
  // })
});

export default connect(mapStateToProps)(Layout);
