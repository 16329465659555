import React from "react";
import styled from "styled-components";
import { Spinner } from "react-bootstrap";

export default ({ visible, spinner = true, centered = false }) => {
  return visible ? (
    <StyledLoader centered={centered}>
      {spinner ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : null}
    </StyledLoader>
  ) : null;
};

const StyledLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 99999999;
  background-color: rgba(54, 54, 54, 0.2);

  & > div {
    margin: 10%;
  }

  ${({ centered }) =>
    centered &&
    ` position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    
    `}
`;
