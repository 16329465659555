import { ADD_FILTERS_PROFILE_LIST } from '../constants';

const initialState = {
    profileListFilters: {
        groups: null,
        staffs: null,
    }
};

export const temporaryDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_FILTERS_PROFILE_LIST:
            return {
                ...state,
                profileListFilters: {
                    ...state.profileListFilters,
                    ...action.payload,
                }
            }
        default: return state;
    }
};
