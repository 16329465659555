export const SET_CRM_LIST = "SET_CRM_LIST";
export const SET_CURRENT_PLAN = "SET_CURRENT_PLAN";
export const SET_CRM_FIELDS = "SET_CRM_FIELDS";
export const RESET_CRM_FIELDS = "RESET_CRM_FIELDS";
export const SET_CURRENT_MAPPING = "SET_CURRENT_MAPPING";
export const SET_REFRESH_DATE_GROUPS = "SET_REFRESH_DATE";
export const SET_REFRESH_DATE_USERS = "SET_REFRESH_DATE_USERS";
export const SET_REFRESH_DATE_PROFILES = "SET_REFRESH_DATE_PROFILES";
export const TYPE_NOTEPAD_TEXT = "TYPE_NOTEPAD_TEXT";
export const SET_ACCOUNT = "SET_ACCOUNT";
export const SET_TEMPORARY_FORMS = "SET_TEMPORARY_FORMS";
export const RESET_TEMPORARY_FORM = "RESET_TEMPORARY_FORM";
export const RESET_FORMS = "RESET_FORMS";
export const ADD_FILTERS_PROFILE_LIST = "ADD_FILTERS_PROFILE_LIST";
export const SET_PROFILE = "SET_PROFILE";

// export const REMOVE_FILTERS_PROFILE_LIST = 'REMOVE_FILTERS_PROFILE_LIST';
