import React from "react";
import { ProfileLayout } from "../../_metronic/layout/profile/ProfileLayout";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom";

import lazyWithRetry from "../helpers/lazyWithRetry";

const Metrics = lazyWithRetry(() => import("../pages/home/Metrics"));

const Overview = lazyWithRetry(() => import("../pages/home/Overview"));

const ProfileInfo = lazyWithRetry(() => import("../pages/home/ProfileInfo"));

const Goals = lazyWithRetry(() => import("../pages/home/Goals"));

const Program = lazyWithRetry(() => import("../pages/home/Program"));
const Testing = lazyWithRetry(() =>
  import("../pages/home/TestingTabs/Testing")
);
const Report = lazyWithRetry(() => import("../pages/home/Report"));

const MetricsList = lazyWithRetry(() => import("../pages/home/MetricsList"));
const GoalsList = lazyWithRetry(() => import("../pages/home/GoalsList"));

const Files = lazyWithRetry(() => import("../pages/home/Files"));
const Notes = lazyWithRetry(() => import("../pages/home/Notes/Notes"));

const ProfileTasks = lazyWithRetry(() => import("../pages/home/ProfileTasks"));
const ProfileCalendar = lazyWithRetry(() =>
  import("../pages/home/ProfileCalendar")
);

const EditCreateWorkout = lazyWithRetry(() =>
  import("../pages/home/ProgramTabs/EditCreateWorkout/EditCreateWorkout")
);

const ProfileSales = lazyWithRetry(() =>
  import("../pages/home/Sales/ProfileSales/ProfileSales")
);

export const ProfileRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <ProfileLayout>
      <Switch>
        <Route path={`${path}/info`} component={ProfileInfo} />
        <Route path={`${path}/metrics`} component={Metrics} />
        <Route path={`${path}/overview`} component={Overview} />
        <Route path={`${path}/goals`} component={Goals} />
        <Route path={`${path}/program`} component={Program} />
        <Route path={`${path}/notes`} component={Notes} />
        <Route path={`${path}/tasks`} component={ProfileTasks} />
        <Route path={`${path}/calendar`} component={ProfileCalendar} />
        <Route path={`${path}/testing`} component={Testing} />
        <Route path={`${path}/report`} component={Report} />
        <Route path={`${path}/files`} component={Files} />
        <Route path={`${path}/sales`} component={ProfileSales} />
        <Route path={`${path}/workouts/create`} component={EditCreateWorkout} />
        <Route path={`${path}/workouts/:id`} component={EditCreateWorkout} />
        <Route path={`${path}/metrics-list`} component={MetricsList} />
        <Route path={`${path}/goals-list`} component={GoalsList} />
      </Switch>
    </ProfileLayout>
  );
};
