import React, { useEffect } from "react";
import { selectors } from "../../ducks/builder";
import { useSelector } from "react-redux";

export function LayoutLoader({ children }) {
  const splashScreenVisible = useSelector(selectors.getIsLoading);

  console.log(splashScreenVisible, "splashScreenVisible");

  useEffect(() => {
    const splashScreen = document.getElementById("splash-screen");

    if (splashScreenVisible) {
      splashScreen.classList.remove("hidden");
    } else {
      splashScreen.classList.add("hidden");
    }
  }, [splashScreenVisible]);

  return children;
}
