import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialState = {
  items: [],
};

export const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    setAccountsList: (state, { payload }) => {
      state.items = payload;
    },
  },
});

export const { setAccountsList } = accountsSlice.actions;

export const selectAccount = (state) => state.accounts.items;

export default accountsSlice.reducer;
