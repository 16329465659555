import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialState = {
  profile: [],
  current: {},
  board: [
    { title: "--", measurement: "--", value: "--" },
    { title: "--", measurement: "--", value: "--" },
    { title: "--", measurement: "--", value: "--" },
    { title: "--", measurement: "--", value: "--" },
  ],
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfilesList: (state, { payload }) => {
      state.items = payload;
    },
    setCurrentProfile: (state, { payload }) => {
      state.current = payload;
    },
    setCurrentProfileBoard: (state, { payload }) => {
      state.board = payload;
    },
  },
});

export const {
  setGroupsList,
  setCurrentProfile,
  setCurrentProfileBoard,
} = profileSlice.actions;

export const selectCurrentProfile = (state) => state.profile.current;
export const selectCurrentProfileBoard = (state) => state.profile.board;

export default profileSlice.reducer;
