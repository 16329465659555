import React from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

// import "./ErrorBoundary.scss";
// import Fallback from "./Fallback";
// import * as Sentry from "@sentry/react";

class ErrorBoundary extends React.Component {
  state = {
    error: false,
    errorInfo: false,
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.errorInfo) {
      // Sentry.captureException(this.state.error);
      // Sentry.captureMessage(this.state.errorInfo);

      return <Redirect to="/error/error-v1" />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
