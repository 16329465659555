import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { getUsersV3 } from "../../crud/info.crud";

function transformArrayToObject(array) {
  return array.reduce((acc, obj) => {
    acc[obj.id] = obj;
    return acc;
  }, {});
}

export const fetchUsersThunk = createAsyncThunk(
  "users/fetchUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getUsersV3();
      return response.data.list;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  items: [],
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsersList: (state, { payload }) => {
      state.items = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsersThunk.fulfilled, (state, { payload }) => {
        state.items = payload;
        state.loading = false;
      })
      .addCase(fetchUsersThunk.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const { setUsersList } = usersSlice.actions;

export const selectUsers = (state) => state.users.items;

export const selectUsersListByGroupId = (groupId) =>
  createSelector([selectUsers], (users) => {
    if (!groupId) return users;
    return users.filter((user) => user.groups.includes(groupId));
  });

export const selectIndexedUsers = createSelector(
  [selectUsers],
  transformArrayToObject
);

export default usersSlice.reducer;
