import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Portlet } from "../partials/content/Portlet";
import { getBoard } from "../crud/profile.crud";
import { selectCurrentProfileBoard } from "../store/reducers/profileSlice";

import { Spinner } from "react-bootstrap";

const DataOutputElem = (props) => (
  <StyledDataOutputElem>
    <div className="dataoutput-data" style={{ color: props.color }}>
      {props.data || "--"}
    </div>
    <div className="dataoutput-title">
      <div className="dataoutput-title__elem">{props.title1}</div>
      <div className="dataoutput-title__elem">{props.title2}</div>
    </div>
  </StyledDataOutputElem>
);

const StyledDataOutputElem = styled.div`
  display: flex;

  .dataoutput {
    &-data {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Poppins";
      font-weight: 500;
      font-size: 25px;
      height: 56px;
      width: 50%;
    }

    &-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;

      &__elem {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #595d6e;
      }
    }
  }

  @media (max-width: 767px) {
    display: block;

    .dataoutput {
      &-data {
        width: 100%;
        font-size: 20px;
        height: auto;
      }
      &-title {
        width: 100%;

        &__elem {
          font-size: 10px;
        }
      }
    }
  }
`;

const DataOutput = (props) => {
  // const refresh = useSelector((state) => state.auth.refreshStatistic);
  const data = useSelector(selectCurrentProfileBoard);
  // const [data, setData] = useState([]);

  // useEffect(() => {
  //   getBoard(props.id).then((res) => {
  //     setData(res.data);
  //   });
  // }, [props.id, refresh]);

  return (
    <StyledDataOutput className="col-12 col-md-6">
      {props.loading && (
        <SpinnerWrapper>
          <Spinner animation="border" role="status"></Spinner>
        </SpinnerWrapper>
      )}

      {data.map((item, index) => {
        return (
          <div className="elem" key={index}>
            <Portlet>
              <DataOutputElem
                data={item.value}
                title1={item.measurement}
                title2={item.title}
                color={"#5d78ff"}
              />
            </Portlet>
          </div>
        );
      })}
    </StyledDataOutput>
  );
};

const StyledDataOutput = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }

  .kt-portlet {
    margin-bottom: 0;
    height: 100%;
  }

  & .elem {
    width: 49%;
    margin-bottom: 5px;

    @media screen and (max-width: 767px) {
      width: 24.7%;
      //   margin: 10px 0;
    }
  }
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

export default DataOutput;
