import { createSlice, createSelector } from "@reduxjs/toolkit";

function transformArrayToObject(array) {
  return array.reduce((acc, obj) => {
    acc[obj.id] = obj;
    return acc;
  }, {});
}

const initialState = {
  items: [],
};

export const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    setGroupsList: (state, { payload }) => {
      state.items = payload;
    },
  },
});

export const { setGroupsList } = groupsSlice.actions;

export const selectGroups = (state) => state.groups.items;

export const selectIndexedGroups = createSelector(
  [selectGroups],
  transformArrayToObject
);

export default groupsSlice.reducer;
