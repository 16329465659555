import React from 'react'
import {
    Modal,
    Button
} from 'react-bootstrap'
import { Typography } from '@material-ui/core';

export default ({modal, setModal}) => {
    return (
        <Modal size="lg" show={modal} onHide={() => setModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Privacy Policy</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Typography variant="h6" gutterBottom>
                What information do we collect?
            </Typography>
            <Typography variant="body1" gutterBottom>
                We collect information from you when you register on our site, subscribe to our newsletter, respond to a survey, fill out a form or use and or download our software.
                When ordering or registering on our site, as appropriate, you may be asked to enter your: name, e-mail address, mailing address, phone number or credit card information. You may, however, visit our site anonymously. Google, as a third party vendor, uses cookies to serve ads on your site. Google's use of the DART cookie enables it to serve ads to your users based on their visit to your sites and other sites on the Internet. Users may opt out of the use of the DART cookie by visiting the Google ad and content network privacy policy.
            </Typography>

            <Typography variant="h6" gutterBottom>
                What do we use your information for?
            </Typography>
            <Typography variant="body1" gutterBottom>Any of the information we collect from you may be used in one of the following ways:</Typography>
            <Typography variant="body1" gutterBottom>; To personalize your experience(your information helps us to better respond to your individual needs)</Typography>
            <Typography variant="body1" gutterBottom>; To improve our website(we continually strive to improve our website offerings based on the information and feedback we receive from you)</Typography>
            <Typography variant="body1" gutterBottom>; To improve customer service(your information helps us to more effectively respond to your customer service requests and support needs)</Typography>
            <Typography variant="body1" gutterBottom>; To process transactions</Typography>
            <Typography variant="body1" gutterBottom>Your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased product or service requested.</Typography>
            <Typography variant="body1" gutterBottom>; To administer a contest, promotion, survey or other site feature</Typography>
            <Typography variant="body1" gutterBottom>; To send periodic emails</Typography>
            <Typography variant="body1" gutterBottom>The email address you provide for order processing will only be used to send you information and updates pertaining to your order.</Typography>

            
            <Typography variant="h6" gutterBottom>
                How do we protect your information?
            </Typography>
            <Typography variant="body1" gutterBottom>
                We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information.
            </Typography>
            <Typography variant="body1" gutterBottom>
                We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Database to be only accessed by those authorized with special access rights to our systems, and are required to keep the information confidential.
            </Typography>
            <Typography variant="body1" gutterBottom>
                After a transaction, your private information (credit cards, social security numbers, financials, etc.) will not be kept on file for more than 60 days.
            </Typography>

            <Typography variant="h6" gutterBottom>
                Do we use cookies?
            </Typography>
            <Typography variant="body1" gutterBottom>
                Yes (Cookies are small files that a site or its service provider transfers to your computers hard drive through your Web browser (if you allow) that enables the sites or service providers systems to recognize your browser and capture and remember certain information
            </Typography>
            <Typography variant="body1" gutterBottom>
                We use cookies to help us remember and process the items in your shopping cart, understand and save your preferences for future visits, keep track of advertisements and compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future. We may contract with third-party service providers to assist us in better understanding our site visitors. These service providers are not permitted to use the information collected on our behalf except to help us conduct and improve our business.
            </Typography>
            <Typography variant="body1" gutterBottom>
                If you prefer, you can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies via your browser settings. Like most websites, if you turn your cookies off, some of our services may not function properly. However, you can still place orders over the telephone or by contacting customer service.
            </Typography>

            <Typography variant="h6" gutterBottom>
                Do we disclose any information to outside parties?
            </Typography>
            <Typography variant="body1" gutterBottom>
                We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.
            </Typography>

            <Typography variant="h6" gutterBottom>
                Third party links
            </Typography>
            <Typography variant="body1" gutterBottom>
                Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.
            </Typography>

            <Typography variant="h6" gutterBottom>
                California Online Privacy Protection Act Compliance
            </Typography>
            <Typography variant="body1" gutterBottom>
                Because we value your privacy we have taken the necessary precautions to be in compliance with the California Online Privacy Protection Act. We therefore will not distribute your personal information to outside parties without your consent.
            </Typography>

            <Typography variant="h6" gutterBottom>
                Childrens Online Privacy Protection Act Compliance
            </Typography>
            <Typography variant="body1" gutterBottom>
                We are in compliance with the requirements of COPPA (Childrens Online Privacy Protection Act), we do not collect any information from anyone under 13 years of age. Our website, products and services are all directed to people who are at least 13 years old or older.
            </Typography>

            <Typography variant="h6" gutterBottom>
                Terms and Conditions
            </Typography>
            <Typography variant="body1" gutterBottom>
                Please also visit our Terms and Conditions section establishing the use, disclaimers, and limitations of liability governing the use of our website at <a href="http://assessandprogress.com" target="_blank" rel="noopener noreferrer">http://assessandprogress.com</a>
            </Typography>

            <Typography variant="h6" gutterBottom>
                Your Consent
            </Typography>
            <Typography variant="body1" gutterBottom>
                By using our site, you consent to our online privacy policy.
            </Typography>

            <Typography variant="h6" gutterBottom>
                Changes to our Privacy Policy
            </Typography>
            <Typography variant="body1" gutterBottom>
                If we decide to change our privacy policy, we will post those changes on this page.
            </Typography>

            <Typography variant="h6" gutterBottom>
                Contacting Us
            </Typography>
            <Typography variant="body1" gutterBottom>
                If there are any questions regarding this privacy policy you may contact us using the information below.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Assess and Progress, Inc<br/>
                838 7th St. #11<br/>
                Santa Monica, California 90403 USA<br/>
                hank@assessandprogress.com
            </Typography>
            <Typography variant="body1" gutterBottom>
                This policy is powered by Trust Guard PCI compliance scans.
            </Typography>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => setModal(false)}>
                    Done
                </Button>
            </Modal.Footer>
        </Modal>
    )
}