import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialState = {
  items: [],
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setSubscriptionList: (state, { payload }) => {
      state.items = payload;
    },
  },
});

export const { setSubscriptionList } = subscriptionSlice.actions;

export const selectCrm = createSelector(
  (state) => state.items,
  (items) => items
);

export default subscriptionSlice.reducer;
