import { SET_CRM_FIELDS, RESET_CRM_FIELDS } from '../constants';

const initialState = null;

export const fieldsCRMReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CRM_FIELDS:
            return action.payload
        case RESET_CRM_FIELDS:
            return null;
        default: return state;
    }
};
