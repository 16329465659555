import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LayoutSplashScreen } from "./LayoutContext";
import * as builder from "../ducks/builder";
import { getAccount } from "../../app/crud/info.crud";
import { setAccount } from "../../app/store/actions";
import { selectAccount } from "../../app/store/reducers/accountsSlice";

/**
 * Used to synchronize current layout `menuConfig`, `layoutConfig` and
 * `htmlClassService` with redux store.
 */
export default function LayoutInitializer({
  children,
  menuConfig,
  layoutConfig,
  htmlClassService,
}) {
  const dispatch = useDispatch();
  const builderState = useSelector(({ builder }) => builder);
  const account = useSelector(selectAccount);

  useEffect(() => {
    dispatch(builder.actions.setMenuConfig(menuConfig));
  }, [dispatch, menuConfig]);

  // useEffect(() => {
  //   getAccount().then((res) => {
  //     const data = res.data.data;
  //     if (data.primary_color) {
  //       layoutConfig.custom_color = data.primary_color;
  //     }
  //     if (data.logo_path) {
  //       layoutConfig.custom_logo = data.logo_path;
  //     }
  //     if (data.file_logo?.path) {
  //       layoutConfig.custom_logo = data.file_logo.path;
  //     }
  //     dispatch(builder.actions.setLayoutConfigs(layoutConfig));
  //     dispatch(setAccount(data));
  //   });
  // }, []);

  useEffect(() => {
    const data = account;
    if (data.primary_color) {
      layoutConfig.custom_color = data.primary_color;
    }
    if (data.logo_path) {
      layoutConfig.custom_logo = data.logo_path;
    }
    if (data.file_logo?.path) {
      layoutConfig.custom_logo = data.file_logo.path;
    }
    dispatch(builder.actions.setLayoutConfigs(layoutConfig));
  }, [account?.id]);

  useEffect(() => {
    if (layoutConfig.demo !== builderState.layoutConfig.demo) {
      dispatch(builder.actions.setLayoutConfigs(layoutConfig));
    }
  }, [dispatch, builderState, layoutConfig]);

  useEffect(() => {
    dispatch(builder.actions.setHtmlClassService(htmlClassService));
  }, [dispatch, htmlClassService]);

  return htmlClassService === builderState.htmlClassServiceObjects ? (
    // Render content when `htmlClassService` synchronized with redux store.
    <>{children}</>
  ) : (
    // Otherwise sow loading splash screen.
    <LayoutSplashScreen />
  );
}
