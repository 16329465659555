import axios from "axios";

export const BASE_URL = process.env.REACT_APP_API_URL;
export const REACT_APP_URL = process.env.REACT_APP_URL;
export const LOGIN_URL = `${BASE_URL}login`;
export const REGISTER_URL = `${BASE_URL}registration`;
export const REGISTER_TRIAL_URL = `${BASE_URL}registration-trial`;
export const REQUEST_PASSWORD_URL = `${BASE_URL}reset-password-request`;
export const RESET_PASSWORD_URL = `${BASE_URL}refresh-password`;

export const ME_URL = `${BASE_URL}user`;
export const AC_URL = `${BASE_URL}account`;

export function login(email, password, token, action) {
  return axios.post(LOGIN_URL, { email, password, recaptcha: token, recaptcha_action: action });
}

export function register(email, firstname, lastname, phone, companyname, token, action, neverbounce_result, field, usertitle, totalclients) {
  return axios.post(REGISTER_URL,
    {
      email,
      first_name: firstname,
      last_name: lastname,
      phone,
      companyName: companyname,
      recaptcha: token,
      recaptcha_action: action,
      neverbounce_result,
      field,
      usertitle,
      totalclients,
    });
}

export function registerTrial(email, firstname, lastname, phone, companyname, token, action, neverbounce_result, field, plan, owner, passcode, usertitle, totalclients) {
  return axios.post(REGISTER_TRIAL_URL,
    {
      email,
      first_name: firstname,
      last_name: lastname,
      phone,
      companyName: companyname,
      recaptcha: token,
      recaptcha_action: action,
      neverbounce_result,
      field,
      plan,
      owner,
      passcode,
      usertitle,
      totalclients,
    });
}

export function requestPassword(email, token, action) {
  return axios.post(REQUEST_PASSWORD_URL, { email, recaptcha: token, recaptcha_action: action });
}

export function resetPassword(token, password) {
  return axios.post(RESET_PASSWORD_URL, { token, password })
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
export function getAccountByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(AC_URL);
}

export function checkTokenPassword(token) {
  return axios.post(`${BASE_URL}token`, { token: token })
}
