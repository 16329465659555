import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import * as auth from "./ducks/auth.duck";
import { metronic } from "../../_metronic";
import { userInfoReducer } from "./reducers/userInfoReducer";
import { fieldsCRMReducer } from "./reducers/fieldsCRMReducer";
import { applicationDataReducer } from "./reducers/applicationDataReducer";
import { temporaryDataReducer } from "./reducers/temporaryDataReducer";
import { groupsSlice } from "./reducers/groupsSlice";
import { usersSlice } from "./reducers/usersSlice";
import { crmSlice } from "./reducers/crmSlice";
import { accountsSlice } from "./reducers/accountsSlice";
import { subscriptionSlice } from "./reducers/subscriptionSlice";
import { profileSlice } from "./reducers/profileSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  user_info: userInfoReducer,
  crmFields: fieldsCRMReducer,
  appData: applicationDataReducer,
  temporaryData: temporaryDataReducer,
  [groupsSlice.name]: groupsSlice.reducer,
  [usersSlice.name]: usersSlice.reducer,
  [crmSlice.name]: crmSlice.reducer,
  [accountsSlice.name]: accountsSlice.reducer,
  [subscriptionSlice.name]: subscriptionSlice.reducer,
  [profileSlice.name]: profileSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
