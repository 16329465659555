import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import UserInfo from "../../../app/components/UserInfo";
import DataOutput from "../../../app/components/DataOutput";
import { getProfile } from "../../../app/crud/profile.crud";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import {
  selectCurrentProfile,
  setCurrentProfile,
} from "../../../app/store/reducers/profileSlice";
import * as AlertState from "../../../app/store/ducks/auth.duck";
import { Redirect } from "react-router-dom";
import Logout from "../../../app/pages/auth/Logout";
import { getBoard } from "../../../app/crud/profile.crud";
import { setCurrentProfileBoard } from "../../../app/store/reducers/profileSlice";
import { getProfileValid } from "../../../app/crud/profile.crud";
import { actions } from "../../ducks/builder";

export const ProfileLayout = ({ children }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [logout, setLogout] = useState(null);
  const profile = useSelector(selectCurrentProfile);
  const refresh = useSelector((state) => state.auth.refreshStatistic);
  const [loading, setLoading] = useState(false);
  const [loadingBoard, setLoadingBoard] = useState(false);
  const [redirect, setRedirect] = useState(false);

  // dispatch(actions.setLoaderScreen(true));

  useEffect(() => {
    const fetchProfileById = async () => {
      setLoading(true);
      try {
        const res = await getProfile(id);
        dispatch(setCurrentProfile(res.data.data));
      } catch ({ response }) {
        if (response && response.status === 401) {
          setLogout(<Logout />);
        }
        if (
          response &&
          response.status === 403 &&
          response.data?.message === "Profile is not allowed for your account"
        ) {
          setLogout(<Redirect to={`/`} />);
          dispatch(
            AlertState.actions.alert({
              text: "Profile is not allowed for your account",
              variant: false,
            })
          );
        }
        response &&
          response.data &&
          response.data.error &&
          dispatch(
            AlertState.actions.alert({
              text: response.data.error,
              variant: false,
            })
          );
      } finally {
        setLoading(false);
      }
    };
    if (profile?.id !== Number(id)) fetchProfileById();
  }, [id]);

  useEffect(() => {
    const fetchBoard = async () => {
      setLoadingBoard(true);
      try {
        const res = await getBoard(id);

        dispatch(setCurrentProfileBoard(res.data));
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingBoard(false);
      }
    };

    if (profile?.id !== Number(id)) fetchBoard();
  }, [id]);

  useEffect(() => {
    const fetchIsValid = async () => {
      try {
        const res = await getProfileValid(id);
        setRedirect(!res.data.is_valid);
        if (!res.data.is_valid) {
          dispatch(
            AlertState.actions.alert({
              text: "Please fill out the required fields below to continue",
              variant: false,
            })
          );
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (profile?.id !== Number(id)) fetchIsValid();
  }, [id]);

  // setTimeout(() => {
  //   dispatch(actions.setLoaderScreen(false));
  // }, 3000);

  return (
    <LayoutWrapper>
      {logout}
      {redirect && <Redirect to={`/profile/${id}/info`} />}
      <div className="row flex-md-row-reverse" style={{ marginBottom: "20px" }}>
        <DataOutput loading={loadingBoard} id={id} />
        <UserInfo loading={loading} id={id} />
      </div>

      {children}
    </LayoutWrapper>
  );
};

const LayoutWrapper = styled.div``;
