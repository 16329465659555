import axios from "axios";

export const BASE_URL = process.env.REACT_APP_API_URL;
export const BASE_URL_V2 = process.env.REACT_APP_API_URL_V2;

export const getOwnGroups = (userId) => {
    return axios.get(`${BASE_URL_V2}users/${userId}/groups`)
};

export function getPrivileges() {
    return axios.get(`${BASE_URL}privileges`)
};

export function getUserPrivileges() {
    return axios.get(`${BASE_URL}user`)
};

export function addPrivileges(data) {
    return axios.post(`${BASE_URL}add-privilege`, data)
};

export function removePrivileges(data) {
    return axios.post(`${BASE_URL}remove-privilege`, data)
};

export function getMetric(type) {
    return axios.get(`${BASE_URL}account-metrics${type ? `?type=${type}` : ''}`)
};

export function updateMetric(data) {
    return axios.post(`${BASE_URL}account-metrics`, data)
};

export function accountAsses() {
    return axios.get(`${BASE_URL}account-assessment`)
};

export function updateAccountAsses(data) {
    return axios.post(`${BASE_URL}account-assessment`, data)
};

export function getInterpretations() {
    return axios.get(`${BASE_URL}account-interpretations`)
};

export function updateInterpretations(data) {
    return axios.post(`${BASE_URL}account-interpretations`, data)
};

export function getAccount() {
    return axios.get(`${BASE_URL}account`)
};

export function updateAccount(data) {
    return axios.post(`${BASE_URL}account`, data)
};

export function updateUserInfo(data) {
    return axios.post(`${BASE_URL}user-info`, data)
};

export function getStatusList() {
    return axios.get(`${BASE_URL}profile/status`)
};

export function editStatus(data) {
    return axios.post(`${BASE_URL}profile/status`, data)
};

export function getBoard(params) {
    return axios.get(`${BASE_URL}account-board`, { params })
};

export function getGroup(search = '', without_mapped = 0) {
    return axios.get(`${BASE_URL}groups?search=${search}&without_mapped=${without_mapped}`)
};

export function getGroupsByUserId(userId) {
    return axios.get(`${BASE_URL_V2}users/${userId}/groups`);
};

export const getUsersV3 = (params) => {
    return axios.get(`${BASE_URL_V2}users`, { params })
};

export const getGroupsByAccountId = (accountId) => {
    return axios.get(`${BASE_URL_V2}accounts/${accountId}/groups`);
};

export function getAllGroup() {
    return axios.get(`${BASE_URL}groups/all`)
};

export function getGroupById(id) {
    return axios.get(`${BASE_URL}groups/${id}`)
};

export function deleteGroups(data) {
    return axios.post(`${BASE_URL}groups/destroy`, data)
};

export function fieldsGroups() {
    return axios.get(`${BASE_URL}groups/create`)
};

export function createGroups(data) {
    return axios.post(`${BASE_URL}groups`, data)
};

export function updateGroups(id, data) {
    return axios.put(`${BASE_URL}groups/${id}`, data)
};

export function fieldsUser() {
    return axios.get(`${BASE_URL}users/create`)
};

export function createUser(data) {
    return axios.post(`${BASE_URL}users`, data)
};

export function getUser(data) {
    return axios.get(`${BASE_URL}users` +
        `?page=${data.page || ''}` +
        `&perPage=${data.perPage || ''}` +
        `&sort=${data.sort || ''}` +
        `&sortBy=${data.sortBy || ''}` +
        `${data.search ? '&search=' + data.search : ''}` +
        `${data.type ? '&type=' + data.type : ''}` +
        `${data.groups === 'all_group_filter_group_dev'
            ? '&groups='
            : data.groups === 'all_user_filter_group_dev'
                ? ''
                : data.groups
                    ? '&groups=' + data.groups
                    : ''}` +
        ``)
};

export function getTesting(data) {
    return axios.get(`${BASE_URL}testings` +
        `?category=${data.category || ''}` +
        `&page=${data.page || '1'}` +
        `&perPage=${data.perPage || ''}` +
        `&sort=${data.sort || ''}` +
        `&sortBy=${data.sortBy || ''}` +
        `${data.search ? '&search=' + data.search : ''}` +
        `${data.subcategory ? '&subcategory=' + data.subcategory : ''}` +
        `${data.groups === 'all_group_filter_group_dev'
            ? '&groups='
            : data.groups === 'all_user_filter_group_dev'
                ? ''
                : data.groups
                    ? '&groups=' + data.groups
                    : ''}` +
        ``)
};

export function deleteUsers(data) {
    return axios.post(`${BASE_URL}users/destroy`, data)
};

export function updateUsers(id, data) {
    return axios.put(`${BASE_URL}users/${id}`, data)
};

export function getUserById(id) {
    return axios.get(`${BASE_URL}users/${id}`)
};

export function massAttachGroups(data) {
    return axios.post(`${BASE_URL}users/attach`, data)
};

export const getUsersV2 = (page = 1, limit = 500) => {
    return axios.get(`${BASE_URL_V2}users?page=${page}&limit=${limit}`);
};

export const getUsers = (search = '', without_mapped = 0) => {
    if (search) {
        return axios.get(`${BASE_URL}users?search=${search}&without_mapped=${without_mapped}`);
    } else {
        return axios.get(`${BASE_URL}users?without_mapped=${without_mapped}`);
    }
};

export function getAllUsersWithoutFiltering(params) {
    return axios.get(`${BASE_URL}users/all`, { params })
};

export function getAllUsers(params = {}) {
    return axios.get(`${BASE_URL}users/all`, { options: { params } })
};

export function getIntegration() {
    return axios.get(`${BASE_URL}account-integrations`)
};

export function updateIntegration(data) {
    return axios.post(`${BASE_URL}account-integrations`, data)
};

export function sendSuggestion(data) {
    return axios.post(`${BASE_URL}suggestion`, data)
}

export function changeMeasurementSystem(id, measurement_system) {
    return axios.post(`${BASE_URL}account/${id}/measurement-system`, { measurement_system });
};

export const getGoogleLink = (userId) => {
    return axios.get(`${BASE_URL_V2}users/${userId}/integrations/google/auth`);
};

export const storeGoogleIntegration = (userId, token) => {
    return axios.post(`${BASE_URL_V2}users/${userId}/integrations/google/auth`, { code: token });
};

export const getGoogleIntegration = (userId) => {
    return axios.get(`${BASE_URL_V2}users/${userId}/integrations/google`);
};

export const disableGoogleIntegration = (userId) => {
    return axios.delete(`${BASE_URL_V2}users/${userId}/integrations/google`)
};
