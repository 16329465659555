/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { LastLocationProvider } from "react-router-last-location";
import ErrorBoundary from "./app/components/ErrorBoundary/ErrorBoundary";

import { I18nProvider, LayoutSplashScreen, ThemeProvider } from "./_metronic";
import AppBuilder from "./AppBuilder";
import * as Sentry from "@sentry/react";

export default function App({ store, persistor, basename }) {
  useEffect(() => {
    window.onload = () => {
      const elem = document.querySelector(".grecaptcha-badge");
      if (elem) elem.style.display = "none";
    };
  }, []);
  useEffect(() => {
    process.env.REACT_APP_INTERCOM_CHAT !== "hidden" &&
      setTimeout(() => {
        const elem = document.querySelector('iframe[title="intercom"] ');
        if (elem) {
          elem.style.right = "0";
        }
      }, 6000);
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={null} showDialog>
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
          {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
          <React.Suspense fallback={<LayoutSplashScreen />}>
            {/* Override `basename` (e.g: `homepage` in `package.json`) */}
            <BrowserRouter basename={basename}>
              {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
              <LastLocationProvider>
                {/* Provide Metronic theme overrides. */}
                <ThemeProvider>
                  {/* Provide `react-intl` context synchronized with Redux state.  */}
                  <I18nProvider>
                    {/* Render routes with provided `Layout`. */}
                    <ErrorBoundary>
                      <AppBuilder />
                    </ErrorBoundary>
                  </I18nProvider>
                </ThemeProvider>
              </LastLocationProvider>
            </BrowserRouter>
          </React.Suspense>
        </PersistGate>
      </Provider>
    </Sentry.ErrorBoundary>
  );
}
