import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
    OverlayTrigger,
    Tooltip
} from 'react-bootstrap';

export default (props) => {
    const { item } = props

    let urlCurrent = props.location

    const editUrl = (url) => {
        let splitUrl = urlCurrent.split('/')
        return url[0] + '/' + splitUrl[2] + '/' + url[1]
    };

    const checkUrl = (url) => {
        let urlCur = urlCurrent.split('/')
        return !!urlCur[3]
            ? url === urlCur[1] + '/' + urlCur[3]
            : url === urlCur[1] + '/' + urlCur[2] || url === urlCur[1]
    };

    return (
        <StyledNavbar>
            <OverlayTrigger
                placement={'right'}
                overlay={
                    <Tooltip id={`tooltip-${item.title}`} className="my-tooltip" contentStyle={{ backgroundColor: 'red' }}>
                        <strong>{item.title}</strong>
                    </Tooltip>
                }
            >
                <Item className={`kt-menu__item ${checkUrl(
                    item.page
                        ? item.page
                        : item.submenu[0].page
                ) ? 'kt-menu__item--active' : ''}`}
                    style={{
                        backgroundColor: checkUrl(
                            item.page
                                ? item.page
                                : item.submenu[0].page
                        ) ? "#1b1b28" : ''
                    }}
                >
                    <Link to={`/${item.page
                        ? item.id
                            ? editUrl(item.page.split('/'))
                            : item.page
                        : item.submenu[0].page
                        }`} className="kt-menu__link kt-menu__toggle"
                        style={{
                            fontSize: '14px'
                        }}
                    >
                        {item.icon &&
                            <span
                                className={`svg-icon svg-icon-primary svg-icon-2x kt-menu__link-icon ${checkUrl(
                                    item.page
                                        ? item.page
                                        : item.submenu[0].page
                                ) ? 'kt-menu__link-icon-active' : ''}`}>{item.icon}</span>}
                    </Link>
                </Item>
            </OverlayTrigger>
        </StyledNavbar>
    )
};

const Item = styled.li`

`;

const StyledNavbar = styled.div`
    .my-tooltip  > .tooltip-inner {
        background-color: red;
    }

    .tooltip-inner {
        background-color: red;
    }
`;
