import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from "react-redux";
import * as AlertState from '../../app/store/ducks/auth.duck'
import { Alert } from 'react-bootstrap'

export default ({ data }) => {
    
    const dispatch = useDispatch();
    const refresh = useSelector(state => state.auth.alert)
    const [time, setTime] = useState()

    useEffect(() => {
        clearTimeout(time)
        data && setTime(setTimeout(() => {
            dispatch(AlertState.actions.alert())
        }, 8000))
    }, [data, refresh]) // eslint-disable-line
    
    return (
        <StyledAlert className={`${!!data ? 'active' : ''}`}>
            {
                !!data &&(
                    <Alert variant={data.variant ? 'success': 'danger'}>
                        {data.text}
                    </Alert>
                )
            }
        </StyledAlert>
    )
}

const StyledAlert = styled.div`
    position: fixed;
    top: -100px;
    left: 50px;
    z-index: 100000;
    transition: top .5s ease-in;

    &.active {
        top: 15px;
    }

    .alert  {
        min-width: 200px;
    }
`